import { useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { appVersionQueryFactory, SALES_CDN } from "@hyperlocal/banking-utility";
import { QueryOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const queryOptions = appVersionQueryFactory.check({
    url: SALES_CDN,
    route,
    storageKey: "SALES_VERSION",
  }) as unknown as QueryOptions;

  return useQuery({
    ...queryOptions,
    useErrorBoundary: false,
  });
}
