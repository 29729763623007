// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getSalesRoutesData } from "@hyperlocal/banking-utility";
import { Route, Routes } from "react-router-dom";
import { useCheckAppVersion } from "../../../api/use-check-app-version";
import { Details, Filters } from "../components";
import { SalesStatement } from "../pages";

export function SalesRoutes() {
  const routes = getSalesRoutesData();
  useCheckAppVersion();

  return (
    <Routes>
      <Route path={routes.salesReport} element={<SalesStatement />} />
      <Route path={routes.salesReportFilters} element={<Filters />} />
      <Route path={routes.salesReportDetails} element={<Details />} />
    </Routes>
  );
}
