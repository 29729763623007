import {
  toast,
  UseSendNativeEvent,
  useSendNativeEvent,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../lib/axios";
import { IMutationConfig } from "../../../lib/react-query";

export interface IDocumentBody {
  Data: string;
  "Valor bruto(R$)": string;
  Tipo: string;
  Parcelas: number;
  Status: string;
  Bandeira: string;
  "Nº do cartão": string;
  NSU: string;
  "COD. autorização": string;
  "Tipo de captura": string;
  Terminal: string;
}

interface IDownloadDocumentProps {
  extension: "pdf" | "xlsx";
  body: IDocumentBody[];
}

export async function downloadDocument({
  extension,
  body,
  isNative,
  sendMessage,
}: IDownloadDocumentProps & UseSendNativeEvent<any>) {
  try {
    const response = await api.post(`desk/export/${extension}`, body, {
      responseType: "arraybuffer",
      headers: {
        Accept:
          extension === "pdf" ? "application/pdf" : "application/vnd.ms-excel",
      },
    });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const buffer = await blob.arrayBuffer();
    const base64 = btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        "",
      ),
    );

    if (base64 && isNative) {
      sendMessage("FILE_DOWNLOAD", {
        base64,
        filename: `relatorio-de-vendas.${extension}`,
      });
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `relatorio-de-vendas.${extension}`);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    console.error("🚀 ~ file: downloadDocument.ts:70 ~ error:", error);
    toast.error("Houve um erro ao gerar o relatório para download.");
  }
}

interface IUseDownloadDocumentProps {
  config?: IMutationConfig<typeof downloadDocument>;
}

export function useDownloadDocument(config: IUseDownloadDocumentProps = {}) {
  const { isNative, sendMessage } = useSendNativeEvent();
  return useMutation({
    ...config,
    mutationFn: (variables: IDownloadDocumentProps) =>
      downloadDocument({
        ...variables,
        isNative,
        sendMessage,
      }),
  });
}
