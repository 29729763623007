// @ts-nocheck
import { Suspense } from "react";
import {
  getVertical,
  Notifications,
  themeProvider,
} from "@hyperlocal/banking-utility";
import { Provider } from "@hyperlocal/vital";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./App.styles";
import { LoadingFallback } from "./components";
import { queryClient } from "./lib/react-query";
import { AppRoutes } from "./routes";
import "./global.css";

export function App() {
  const theme = getVertical();

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <Suspense fallback={<LoadingFallback />}>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
              <GlobalStyles />
              <AppRoutes />
            </QueryClientProvider>
          </Suspense>
        </BrowserRouter>
        <Notifications />
      </ThemeProvider>
    </Provider>
  );
}
